import { selfSelectedUserRoles } from '@oup/shared-node-browser/user.js';
// Constants
import { isPlacementCentre } from '@oup/shared-node-browser/org';
import userRoles, { roleIsAtLeast, getSelfSelectedRole } from '../../../globals/userRoles';
import { HubLayoutConstants } from '../../../globals/hubConstants';
// Services
import processUserRole from './processUserRole';
// Components
import { GLYPHS } from '../../../components/SVGIcon/SVGIcon';

import { isLtiMode } from '../../../utils/platform';
import { featureIsEnabled } from '../../../globals/envSettings';

const sideNavItems = (
  hubContent = {},
  userRole = '',
  organization = {},
  { openRedeemModal, openJoinClassWithCodeModal, openOnboardingWizard, openEnterCodeModal },
  currentOrganisationLti,
  hasCourses
) => {
  const {
    SIDE_NAV: {
      showClassesTab = false,
      showAllButton = false,
      showJoinClassWithCodeSideButton = false,
      showMyProgressTab = false,
      showNote = false
    } = {},
    COMMON: { showCreateClassButton = false } = {}
  } = processUserRole(userRole, hubContent);

  const showMyOrganization =
    roleIsAtLeast(userRoles.TEACHER_ADMIN, userRole) &&
    !HubLayoutConstants.OUP_ROLES.includes(userRole) &&
    !isLtiMode();

  const isOrgPlacementCentre = isPlacementCentre(organization?.orgConfig);

  const showMyTasksTab = userRole === userRoles.LEARNER && featureIsEnabled('opt-main-features');

  let showMyClassesTab = false;
  if (featureIsEnabled('opt-main-features') && isOrgPlacementCentre) showMyClassesTab = false;
  else showMyClassesTab = showClassesTab && (isLtiMode() || !currentOrganisationLti);

  let showMyCoursesTab = false;
  if (featureIsEnabled('opt-main-features') && isOrgPlacementCentre) showMyCoursesTab = !!hasCourses;
  else showMyCoursesTab = true;

  const myOrganisationLink = currentOrganisationLti
    ? `${HubLayoutConstants.PATH_NAMES.ORGANIZATION_PATH}${HubLayoutConstants.PATH_NAMES.LMS_CONFIG}`
    : `${HubLayoutConstants.PATH_NAMES.ORGANIZATION_PATH}${HubLayoutConstants.PATH_NAMES.STUDENTS}`;

  const selfSelectedRole = getSelfSelectedRole();

  const tabsMap = {
    home: {
      id: 'nav-0',
      navText: hubContent.home,
      path: HubLayoutConstants.PATH_NAMES.DASHBOARD_PATH,
      icon: GLYPHS.ICON_DASHBOARD,
      linkTo: HubLayoutConstants.PATH_NAMES.DASHBOARD_PATH,
      action: '',
      canToggle: false,
      tabindex: '0',
      ariaLabel: 'Home',
      showTab: true,
      toggledItems: {}
    },
    myOrganization: {
      id: 'nav-1',
      navText: hubContent.my_organization,
      navSubTitle: organization.name,
      path: HubLayoutConstants.PATH_NAMES.ORGANIZATION_PATH,
      icon: GLYPHS.ICON_ORG,
      linkTo: myOrganisationLink,
      action: '',
      disabled: !showMyOrganization,
      canToggle: false,
      tabindex: '0',
      ariaLabel: 'My Organization',
      showTab: true,
      toggledItems: {}
    },
    myTasks: {
      id: 'nav-2',
      navText: hubContent.my_tasks,
      path: HubLayoutConstants.PATH_NAMES.TASKS_PATH,
      icon: GLYPHS.ICON_FILE_RESOURCE,
      linkTo: HubLayoutConstants.PATH_NAMES.TASKS_PATH,
      action: '',
      disabled: !showMyTasksTab,
      canToggle: false,
      tabindex: '0',
      ariaLabel: 'My Tasks',
      showTab: true,
      tasksNumber: 1,
      toggledItems: {}
    },
    myClasses: {
      id: 'nav-3',
      testHook: 'HUB_NAVIGATION_BAR_MY_CLASSES',
      navText: hubContent.my_classes,
      path: HubLayoutConstants.PATH_NAMES.CLASSES_PATH,
      icon: GLYPHS.ICON_CLASS,
      action: '',
      disabled: false,
      canToggle: true,
      tabindex: '0',
      ariaLabel: 'My Classes Toggle button',
      showTab: showMyClassesTab,
      toggledItems: {
        showActionButton: showCreateClassButton && !currentOrganisationLti && !featureIsEnabled('opt-main-features'),
        showAllButton,
        showAllLabel: hubContent.show_all_classes,
        showAllIcon: GLYPHS.ICON_TILES,
        actionButtonLabel: hubContent.create_class,
        handleActionButton: openOnboardingWizard,
        actionButtonTestHook: 'OPEN_ONBOARDING_WIZARD_BUTTON_NAVIGATION_BAR',
        actionButtonAriaLabel: hubContent.create_class_screen_reader,
        showJoinClassWithCodeSideButton,
        joinClassWithCodeButtonLabel: hubContent.join_class_with_code,
        joinClassWithCodeButtonAriaLabel: hubContent.join_class_with_code_screen_reader,
        handleJoinClassWithCodeButton: openJoinClassWithCodeModal,
        showNote
      }
    },
    myCourses: {
      id: 'nav-4',
      navText: hubContent.my_courses,
      path: HubLayoutConstants.PATH_NAMES.COURSES_PATH,
      icon: GLYPHS.ICON_COURSE,
      action: '',
      canToggle: true,
      tabindex: '0',
      ariaLabel: 'My Courses Toggle button',
      showTab: showMyCoursesTab,
      toggledItems: {
        showActionButton: !currentOrganisationLti && !featureIsEnabled('opt-main-features'),
        showAllButton: true,
        showAllLabel: hubContent.show_all_courses,
        showAllIcon: GLYPHS.ICON_COURSE,
        actionButtonLabel: hubContent.enter_a_code_text,
        handleActionButton: openRedeemModal,
        actionButtonAriaLabel: hubContent.redeem_code_screen_reader
      }
    },
    myProgress: {
      id: 'nav-5',
      navText: hubContent.my_progress,
      path: HubLayoutConstants.PATH_NAMES.MY_PROGRESS,
      icon: GLYPHS.ICON_PROGRESS,
      linkTo: HubLayoutConstants.PATH_NAMES.MY_PROGRESS,
      disabled: false,
      action: '',
      canToggle: false,
      tabindex: '0',
      ariaLabel: 'My Progress button',
      showTab: showMyProgressTab
    },
    enterACodeBtn: {
      id: 'nav-6',
      isButton: true,
      disabled: !featureIsEnabled('opt-main-features'),
      icon: GLYPHS.ICON_PLUS,
      onClick:
        userRole === userRoles.LEARNER ||
        (userRole === userRoles.USER && selfSelectedRole === selfSelectedUserRoles.SELF_LEARNER)
          ? openEnterCodeModal
          : openRedeemModal,
      text: hubContent.enter_a_code,
      ariaAttributes: { label: hubContent.enter_a_code },
      variant: 'filled',
      dataAttributes: { testId: 'ENTER_CODE_BUTTON_PRIMARY_NAVIGATION_BAR' }
    }
  };

  if ((isOrgPlacementCentre || organization?.isPlacementCentre) && userRole === userRoles.LEARNER && !hasCourses) {
    return [tabsMap.home, tabsMap.myTasks, tabsMap.enterACodeBtn];
  }
  return Object.values(tabsMap);
};

export default sideNavItems;
